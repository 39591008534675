import React from 'react';
import Hero from "../../Layouts/Hero/Hero"
import "./home.scss"
// import SlideShow from "../../Components/SlideShow/SlideShow";
import bitcoin from "../../assets/downgraded/bitcoin.gif"
import key1 from "../../assets/downgraded/key-value-1.png"
import key2 from "../../assets/downgraded/key-value-2.png"
import key3 from "../../assets/downgraded/key-value-3.png"
import key4 from "../../assets/downgraded/key-value-4.png"
import Button from "../../Components/Button/Button";
import Section from "../../Layouts/Section/Section";
import IllustrazioneHero from '../../Components/Illustrazioni/IllustrazioneHero';
import SectionExchange from '../../Sections/SectionExchange/SectionExchange';
import SectionPartners from '../../Sections/SectionPartners/SectionPartners';
import SectionBar from '../../Sections/SectionBar/SectionBar';
import SectionJob from '../../Sections/SectionJob/SectionJob';
import { Helmet } from "react-helmet";
import ita from './ita.json'
import eng from './eng.json'

function Home(props) {
  let language = props.isEng ? eng : ita
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div id="Home" >
      {
        props.isEng?
        <Helmet>
        <title>Coinbar | Exchange & Bar</title>
        <meta name="title" content="Coinbar" />
        <meta name="description" content="Coinbar is a reliable and certified Italian cryptocurrency exchange, we offer our users personal and business accounts. Enter the future!" />
        <meta name="keywords" lang="eng" content="exchange, crypto, aziendale, digitalbits, coinbar, trading" />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
      </Helmet>
      :
      <Helmet>
        <title>Coinbar | Exchange & Bar</title>
        <meta name="title" content="Coinbar" />
        <meta name="description" content="Coinbar è un exchange di criptovalute italiano affidabile e certificato, offriamo ai nostri utenti account personali e aziendali. Entra nel futuro!" />
        <meta name="keywords" lang="it" content="exchange, criptovalute, aziendale, digitalbits, coinbar, trading" />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="Italian" />
      </Helmet>
      }
      <Hero>
        <div id="HeroBanner" className="flex-container vh-100 justify-center flex-column">
          <div className="flex-1 align-items-center flex-container  flex-column align-center justify-center base-padding-side">
            {/* <IllustrazioneHero /> */}
            <h1 className="text-white z-index-1 hero-heading text-center">{language.h1[0]}<br />{language.h1[1]}</h1>
            <p className="text-white z-index-1 max-width-70 text-center">{language.subtitle}</p>
            <div className="button-stacks">
              <Button white={true} link="exchange" interno={false}>{language.button1}</Button>
              {/* <Button white={true} outline={true} link="bar" interno={false}>{language.button2}</Button> */}
              <Button white={true} outline={false} link="https://pay.coinbar.io/" target="_blank" exit>{language.button3}</Button>
            </div>
          </div>

        </div>
      </Hero>

      <Section id="About">
        <img id="coin-1" className="z-index-0" src={bitcoin} alt="un bitcoin"/>
        <div className="flex-container justify-center">
          <div className="flex-1 max-width-60% position-relative">
            <h2 className="text-center hero-heading">{language.exchangeh2}</h2>
            <p className="text-center">{language.exchangep}</p>
            {/* <img id="coin-2" className="z-index-0" src={bitcoin} alt="un bitcoin"/>
            <img id="coin-3" className="z-index-0" src={bitcoin} alt="un bitcoin"/> */}
          </div>
        </div>
        <div className="button-stacks">
            <Button white={false} link="exchange" interno={false}>{language.button1}</Button>
            <Button white={false} outline={true} link="bar" interno={false}>{language.button2}</Button>
          </div>
        <div className="double-base-padding-side margin-bottom-150">
          <div className="flex-container gap-50 margin-top-150 column-on-mobile">
            <div className="flex-1 flex-container flex-column  align-items-center">
              <img className="key-value" src={key1} alt="Icona di una certificazione"/>
              <h3 className="sub-heading text-center">{language.feature1.h3}</h3>
              <p className="text-center">{language.feature1.p}</p>
            </div>
            <div className="flex-1  flex-container flex-column align-items-center">
              <img className="key-value" src={key2} alt="Icona di un razzo"/>
              <h3 className="sub-heading text-center">{language.feature2.h3}</h3>
              <p className="text-center">{language.feature2.p}</p>
            </div>
            <div className="flex-1  flex-container flex-column align-items-center">
              <img className="key-value" src={key3} alt="Icona di pagamento"/>
              <h3 className="sub-heading text-center">{language.feature3.h3}</h3>
              <p className="text-center">{language.feature3.p}</p>
            </div>
            <div className="flex-1  flex-container flex-column align-items-center">
              <img className="key-value" src={key4} alt="Icona del trading"/>
              <h3 className="sub-heading text-center">{language.feature4.h3}</h3>
              <p className="text-center">{language.feature4.p}</p>
            </div>
          </div>
        </div>
        {/* <div id="Features" className="double-base-padding-side margin-top-150 margin-bottom-200">
          <div className="flex-container justify-center">
            <div className="flex-1 align-center max-width-70">
              <h2 className=" section-heading text-center" >{language.featuresh2}</h2>
              <SlideShow isEng={props.isEng}/>
            </div>
          </div> */}
          
      </Section>
      <SectionExchange isEng={props.isEng}/>
      <SectionPartners isEng={props.isEng}/>
      <SectionBar isEng={props.isEng}/>
      <SectionJob isEng={props.isEng}/>
    </div>
  );
}

export default Home;
