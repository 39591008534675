import "./button.scss"
import { useNavigate } from "react-router-dom";

function Button(props) {
  let navigate = useNavigate();
  const goToInterno=()=>{ 
    window.location.href="#"+props.link
  }
  const goToPage=()=>{ 
    props.exit?
    window.open(props.link, "_blank", "noopener,noreferrer")
    :
    navigate("../"+props.link, { replace: true })
  }
  return (
    <div className="button-wrapper">

      {props.outline ?
        <button type="button" onClick={props.interno?()=>goToInterno():()=>goToPage()} className={props.white ? "button-coinbar-white outline" : "button-coinbar outline"}>{props.children}</button> :
        <button type="button" onClick={props.interno?()=>goToInterno():()=>goToPage()}  className={props.white ? "button-coinbar-white" : "button-coinbar"}>{props.children}</button>
      }
    </div>
  );
}

export default Button;
