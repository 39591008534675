import React from 'react';
import Hero from "../../Layouts/Hero/Hero"
import "./sectionexchange.scss"
import ita from './ita.json'
import eng from './eng.json'
import Button from "../../Components/Button/Button";
import IllustrazioneExchange from '../../Components/Illustrazioni/IllustrazioneExchange';

function SectionExchange(props) {
    let language = props.isEng ? eng : ita
    return (

        <Hero>
            <div id="exchange-section" className="flex-container vh-100 double-base-padding-side background-green justify-center base-padding-topdown">
                <div className="flex-1 align-center max-width-70">
                    <IllustrazioneExchange />
                    <h2 className="margin-top-20 text-center text-white hero-heading">{language.h2}</h2>
                    <div className="button-stacks">
                        <Button white={true} link="exchange" interno={false}>{language.button[0]}</Button>
                        <Button white={true} outline={true} link="https://trade.coinbar.io/login" interno={false} exit>{language.button[1]}</Button>
                    </div>
                </div>
            </div>
        </Hero>
    );
}

export default SectionExchange;
