import React from 'react';
import "./sectionpartner.scss"
import Button from "../../Components/Button/Button";
import Partners from '../../Components/Partners/Partners';
import IllustrazionePartners from '../../Components/Illustrazioni/IllustrazionePartners';
import ita from './ita.json'
import eng from './eng.json'
function SectionPartners(props) {
  let language = props.isEng ? eng : ita
  return (

      <section id="Partners" className="">
        <div className="flex-container justify-center double-base-padding-side ">
          <div className="flex-1 align-center max-width-70">
            <IllustrazionePartners />
            <h2 className="text-center hero-heading">{language.h2}</h2>
            <p className="text-center ">
            {language.p[0]}
             <br />
            {language.p[1]}
            </p>
            <Partners />
            <div className="margin-top-50 button-stacks" style={{fontWeight: "bold"}}>
            <Button white={false} link="b2b" interno={false}>{language.button}</Button>
            <Button white={false} link="https://pay.coinbar.io" interno={false} exit>CoinbarPay</Button>
            </div>

          </div>
        </div>

      </section>
      
  );
}

export default SectionPartners;
