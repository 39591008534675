import "./partners.scss";
import partner1 from "../../assets/partner/chili_horiz_black.png";
import partner2 from "../../assets/partner/TradingView-Logo.png";
import partner3 from "../../assets/partner/chili_horiz_black.png";
import partner4 from "../../assets/partner/coint_horiz_black.png";
import partner5 from "../../assets/partner/doppelganger_horiz_black.png";
import partner6 from "../../assets/partner/liguori_horiz_black.png";
import partner7 from "../../assets/partner/nuvolari_horiz_black.png";
import partner8 from "../../assets/partner/kebhouze.png";
import partner9 from "../../assets/partner/Proj3ct_Black.png";
import partner10 from "../../assets/partner/logo_utravel_black.png";

function Partners(props) {
  return (
    <div>
      {/*<h4 className="partners-heading">Alcuni dei partner che hanno già scelto Coinbar</h4>*/}
      <div className="partners-wrapper">
  <a href="https://www.tradingview.com" target="_blank" rel="noopener noreferrer"><img className="partner-slide" src={partner2} alt="Logo di Tradingview" /></a>
  <a href="https://www.chili.tv" target="_blank" rel="noopener noreferrer"><img className="partner-slide" src={partner3} alt="Logo di Chili Tv" /></a>
  <a href="https://www.cointelegraph.it" target="_blank" rel="noopener noreferrer"><img className="partner-slide" src={partner4} alt="Logo di Cointelegraph" /></a>
  <a href="https://www.doppelganger.com" target="_blank" rel="noopener noreferrer"><img className="partner-slide" src={partner5} alt="Logo di Doppelganger" /></a>
  <a href="https://www.liguorigioielli.it" target="_blank" rel="noopener noreferrer"><img className="partner-slide" src={partner6} alt="Logo di Liguori" /></a>
  <a href="https://www.nuvolari.biz" target="_blank" rel="noopener noreferrer"><img className="partner-slide" src={partner7} alt="Logo di Nuvolari" /></a>
  <a href="https://www.kebhouze.com" target="_blank" rel="noopener noreferrer"><img className="partner-slide" src={partner8} alt="Logo di Kebhouze" /></a>
  <a href="https://www.proj3ct.it" target="_blank" rel="noopener noreferrer"><img className="partner-slide" src={partner9} alt="Logo di Proj3ct" style={{maxWidth: "100px"}} /></a>
  <a href="https://www.utravel.it" target="_blank" rel="noopener noreferrer"><img className="partner-slide" src={partner10} alt="Logo di Utravel" /></a>

</div>
    </div>
  );
}

export default Partners;
