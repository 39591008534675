import React from 'react'
import Hero from "../../Layouts/Hero/Hero"
import "./landingExchange.scss"
// import SlideShow from "../../Components/SlideShow/SlideShow";
import bitcoin from "../../assets/bitcoin.gif"
import key1 from "../../assets/key-value-1.png"
import key2 from "../../assets/key-value-2.png"
import key3 from "../../assets/key-value-3.png"
import key4 from "../../assets/key-value-4.png"
import Button from "../../Components/Button/Button";
import Section from "../../Layouts/Section/Section";
import TradingCard from '../../Components/Grafico/TradingCard';
import IllustrazioneExchange from '../../Components/Illustrazioni/IllustrazioneExchange';
import SectionPartners from '../../Sections/SectionPartners/SectionPartners';
import SectionBar from '../../Sections/SectionBar/SectionBar';
import SectionJob from '../../Sections/SectionJob/SectionJob';

import { Helmet } from 'react-helmet';
import ita from './ita.json'
import eng from './eng.json'
import FallbackTC from './FallbackTC';
import axios from 'axios';


// const axios = require('axios').default;

function LandingExchange(props) {
  let language = props.isEng ? eng : ita
  React.useEffect(() => {
    window.scrollTo(0, 0)
    getPrices().then(() => { console.log(prezzi) })
  }, [])
  const [prezzi, setPrezzi] = React.useState(false)
  const [check, setCheck] = React.useState(false)

  const getPrices = async () => {
    axios.get('https://api.cryptosrvc.com/exchange/quotes?exchange=COINBAR')
      .then(function (response) {
        // handle success
        setPrezzi(response.data)
        console.log(response.data, prezzi)
      })
      .catch(function (error) {
        // handle error
        console.log("Errore in getPrices =>", error);
      })
      .finally(function () {
        setCheck(true)
      });
  }
  const calculatePercentageDiff = (ask, bid, change) => {
    let currentPrice = (parseFloat(ask) + parseFloat(bid)) / 2
    let startPrice = currentPrice - parseFloat(change)
    let endPrice = currentPrice
    const increase = new Number(endPrice) - new Number(startPrice);
    const percentagediff = ((increase / new Number(startPrice)) * 100).toFixed(2)
    return percentagediff
  }
  return (
    <div id="Exchange" >
      {
        props.isEng ?
          <Helmet>
            <title>Coinbar | Exchange</title>
            <meta name="title" content="Coinbar | Exchange" />
            <meta name="description" content="Coinbar is a reliable and certified Italian cryptocurrency exchange, we offer our users personal and business accounts. Enter the future!" />
            <meta name="keywords" lang="eng" content="exchange, criptovalute, aziendale, digitalbits, coinbar, trading" />
            <meta name="robots" content="index, follow" />
            <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
            <meta name="language" content="English" />
          </Helmet>
          :
          <Helmet>
            <title>Coinbar | Exchange</title>
            <meta name="title" content="Coinbar | Exchange" />
            <meta name="description" content="Coinbar è un exchange di criptovalute italiano affidabile e certificato, offriamo ai nostri utenti account personali e aziendali. Entra nel futuro!" />
            <meta name="keywords" lang="it" content="exchange, criptovalute, aziendale, digitalbits, coinbar, trading" />
            <meta name="robots" content="index, follow" />
            <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
            <meta name="language" content="Italian" />
          </Helmet>
      }

      <Hero>
        <div id="HeroBanner" className="flex-container vh-100 justify-center flex-column ">
          <div className="flex-1 flex-container  flex-column align-center justify-center  base-padding-side">
            <IllustrazioneExchange />
            <h1 className="text-white z-index-1 max-width-50 hero-heading text-center">{language.h1}</h1>
            <p className="text-white z-index-1 max-width-50 text-center">{language.subtitle}</p>
            <div className="button-stacks">
              <Button white={true} link="https://trade.coinbar.io/login" interno={false} exit>{language.button[0]}</Button>
              <Button white={true} outline={true} link="https://pay.coinbar.io" interno={false} exit>{language.button[1]}</Button>
            </div>
          </div>
        </div>
      </Hero>
      <div className="trading-cards">
      {
        prezzi.length > 0 &&
        prezzi.map((pair) => (
          <TradingCard
            key={pair.pair}
            coppia={pair.pair}
            ask={pair.ask}
            bid={pair.bid}
            perc={pair.price_24h_change}
          />

        ))
      }
      </div>
        <Section id="About" >
        <img id="coin-1" className="z-index-0" src={bitcoin} alt="un bitcoin"/>
        <div className="flex-container justify-center">
          <div className="flex-1 max-width-60% position-relative">
            <h2 className="text-center hero-heading">{language.h2}</h2>
            <p className="text-center">{language.paragraph[0]}<a>{language.paragraph[1]}</a> {language.paragraph[2]}</p>            {/* <img id="coin-2" className="z-index-0" src={bitcoin} alt="un bitcoin"/>
            <img id="coin-3" className="z-index-0" src={bitcoin} alt="un bitcoin"/> */}
          </div>
        </div>
        <div className="button-stacks">
            <Button white={false} link="exchange" interno={false}>{language.button[1]}</Button>
            <Button white={false} outline={true} link="bar" interno={false}>{language.button[2]}</Button>
          </div>
        <div className="double-base-padding-side margin-bottom-150">
          <div className="flex-container gap-50 margin-top-150 column-on-mobile">
          <div className="flex-1 flex-container flex-column  align-items-center">
              <img className="key-value" src={key1} />
              <h3 className="sub-heading text-center">{language.h3[0]}</h3>
              <p className="text-center">{language.p[0]}</p>
            </div>
            <div className="flex-1  flex-container flex-column align-items-center">
              <img className="key-value" src={key2} />
              <h3 className="sub-heading text-center">{language.h3[1]} </h3>
              <p className="text-center">{language.p[1]}</p>
            </div>
            <div className="flex-1  flex-container flex-column align-items-center">
              <img className="key-value" src={key3} />
              <h3 className="sub-heading text-center">{language.h3[2]}</h3>
              <p className="text-center">{language.p[2]}<br />{language.p[3]}</p>
            </div>
            <div className="flex-1  flex-container flex-column align-items-center">
              <img className="key-value" src={key4} />
              <h3 className="sub-heading text-center">{language.h3[3]}</h3>
              <p className="text-center">{language.p[4]}</p>
            </div>
          </div>
        </div>
        {/* <div id="Features" className="double-base-padding-side margin-top-150 margin-bottom-200">
          <div className="flex-container justify-center">
            <div className="flex-1 align-center max-width-70">
              <h2 className=" section-heading text-center" >{language.featuresh2}</h2>
              <SlideShow isEng={props.isEng}/>
            </div>
          </div> */}
          
      </Section>
      <Hero>
        <div id="exchange-section" className="flex-container vh-100 double-base-padding-side background-green justify-center base-padding-topdown">
          <div className="flex-1 align-center max-width-50 flex-container  flex-column align-items-center margin-top-150 margin-bottom-150">

            <div className="screen-product-wrap">
              <div />
            </div>
            <h2 className="margin-top-20 text-center text-white hero-heading">{language.investi}</h2>
            <p className="text-center text-white">{language.p[5]}<br />{language.p[6]}
            </p>
            <div className="margin-top-50"><Button white={true} link="https://trade.coinbar.io/login" interno={false} exit>{language.button[4]}</Button></div>
          </div>
        </div>
      </Hero>
      <SectionPartners isEng={props.isEng} />
      <SectionBar isEng={props.isEng} />
      <SectionJob isEng={props.isEng} />
    </div>
  );
}

export default LandingExchange;
