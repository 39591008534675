import React from "react"
import Hero from "../../Layouts/Hero/Hero"
import "./b2b.scss"
import key4 from "../../assets/Mockup.png"
import Button from "../../Components/Button/Button";
import FormB2B from "../../Components/Forms/FormB2B"
import { Helmet } from "react-helmet"
import ita from './ita.json'
import eng from './eng.json'
function B2B(props) {
  let language = props.isEng ? eng : ita
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div id="b2b" >
      <Helmet>
        <title>Coinbar per Aziende</title>
        <meta name="title" content="Coinbar per Aziende" />
        <meta name="description" content="Coinbar è un exchange di criptovalute italiano affidabile e certificato, con noi puoi aprire il tuo account aziendale in pochi passi e iniziare ad accettare pagamenti in crypto." />
        <meta name="keywords" lang="it" content="exchange, criptovalute, aziendale, digitalbits, coinbar, trading" />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="Italian" />
      </Helmet>
      <Hero>
        <div id="HeroBanner" className="flex-container vh-100 justify-center flex-column">
          <div className="flex-1 flex-container  flex-column align-center justify-center  base-padding-side margin-top-100">
            <h1 className="text-white z-index-1 max-width-70 hero-heading text-center">{language.h1}</h1>
            <p className="text-white z-index-1 max-width-70 text-center">{language.subtitle}</p>
          </div>
          {/* <div className="partners-slider" /> */}
        </div>
      </Hero>
      <section id="About" className="double-base-padding-side margin-top-100">

        <div className="flex-container gap-50 margin-top-150 margin-bottom-200 column-on-mobile  ">
          <div className="flex-1 flex-container flex-column  align-items-center">
            <h3 className="sub-heading text-center">{language.feature1.h3}</h3>
            <p className="text-center">{language.feature1.p}</p>
          </div>
          <div className="flex-1  flex-container flex-column align-items-center">
            <h3 className="sub-heading text-center">{language.feature2.h3}</h3>
            <p className="text-center">{language.feature2.p}</p>
          </div>
          <div className="flex-1  flex-container flex-column align-items-center">
            <h3 className="sub-heading text-center">{language.feature3.h3}</h3>
            <p className="text-center">{language.feature3.p}</p>
          </div>
          <div className="flex-1  flex-container flex-column align-items-center">
            <h3 className="sub-heading text-center">{language.feature4.h3}</h3>
            <p className="text-center">{language.feature4.p}</p>
          </div>
        </div>
      </section>

      <Hero>
        <div id="coinbarpay-section" className="flex-container vh-100 double-base-padding-side background-green justify-center base-padding-topdown">
          <div className="flex-1 align-center max-width-50 flex-container  flex-column align-items-center margin-top-150 margin-bottom-150">
            <div className="screen-product-wrap-cbpay">
              <img src={key4} alt="Mockup dell'app di Coinbar Pay" className="rotate-image" />
            </div>
            <h2 className="margin-top-40 text-center text-white hero-heading">{language.coinbarpayh2}</h2>
            <p className="text-center text-white">
              {language.coinbarpayp[0]}<br />
              {language.coinbarpayp[1]} <br />
              {language.coinbarpayp[2]}<br />
              {language.coinbarpayp[3]}
            </p>
            <div className="margin-top-50"><Button white={true} link="https://pay.coinbar.io" exit interno={false}>{language.coinbarpaybutton}</Button></div>
          </div>
        </div>
      </Hero>
      <section id="form" className="margin-bottom-200 margin-top-200">
        <div className="flex-container justify-center double-base-padding-side ">
          <div className="flex-1 align-center max-width-50">
            <h2 className="text-center hero-heading">{language.formh2}</h2>
            <FormB2B isEng={props.isEng} />
          </div>
        </div>
      </section>
    </div>
  );
}

export default B2B;
