import React from "react";
import "./header.scss";
import "./announcePop.scss";
import logoWhite from "../../assets/coinbar-horiz-white.svg";
import logoPos from "../../assets/logo-coinbar-hor.svg";

import twitterLogo from "../../assets/twitter.png";
import facebookLogo from "../../assets/facebook.png";
import instagramLogo from "../../assets/instagram.png";
import twitterLogoB from "../../assets/twitter-b.png";
import facebookLogoB from "../../assets/facebook-b.png";
import instagramLogoB from "../../assets/instagram-b.png";
import telegramIcon from "../../assets/telegramB.png";
import telegramIconB from "../../assets/telegram.png";
import Hamburger from "../Hamburger/Hamburger";
import itFlag from "../../assets/italy.png";
import engFlag from "../../assets/united-kingdom.png";

function Header(props) {
  React.useEffect(() => {
    props.getMessage(props.isEng);
  }, [props.isEng]);

  const changeLanguage = () => {
    props.setEng(!props.isEng);
    props.setChangedLang(true);
  };
  if (props.active === true) {
    return (
      <>
        <div
          className="sticky-banner zoom-text"
          style={{
            position: 'sticky', top: 0, 
            backgroundColor: "#5476d2",
            padding: "2px",
            height: "50px",
            zIndex: 40,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p style={{ margin: 0, textAlign: "center", color: "white" }}>
          <a href="https://pay.coinbar.io" style={{color:"white"}} target="_blank" rel="noreferrer" >
            {props.isEng
              ? "Start accepting crypto payments with  CoinbarPay - Discover More!"
              : "Accetta ora pagamenti in crypto con CoinbarPay - Scopri di più!"}
         </a>
          </p>
        </div>
        <header className="active">
          <div className="social">
            <img
              onClick={changeLanguage}
              src={props.isEng ? itFlag : engFlag}
              className="language-flag"
              alt={
                props.isEng
                  ? "bandiera italiana per la selezione della lingua"
                  : "bandiera inglese per la selezione della lingua"
              }
            />
            <a href="https://twitter.com/CoinbarOfficial">
              <img src={twitterLogoB} alt="logo di twitter" />
            </a>
            <a href="https://www.facebook.com/CoinbarSpa">
              <img src={facebookLogoB} alt="logo di facebook" />
            </a>
            <a href="https://www.facebook.com/romacoinbar">
              <img src={instagramLogoB} alt="logo di instagram" />
            </a>
            <a href="https://t.me/CoinbarITA">
              <img src={telegramIconB} alt="logo di telegram" />
            </a>
          </div>
          <div className="logo">
            <a href="https://www.coinbar.io">
              <img src={logoPos} alt="logo di coinbar" />
            </a>
          </div>
          <div className="nav">
            <Hamburger active={true} />
          </div>
        </header>
      </>
    );
  } else {
    return (
      <>
        <div
          className="sticky-banner zoom-text"
          style={{
            backgroundColor: "#5476d2",
            padding: "2px",
            height: "50px",
            zIndex: 40,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p style={{ margin: 0, textAlign: "center", color: "white" }} >
           <a href="https://pay.coinbar.io" style={{color:"white"}} target="_blank" rel="noreferrer">
            {props.isEng
              ? "Start accepting crypto payments with CoinbarPay - Discover More"
              : "Accetta ora pagamenti in crypto con CoinbarPay - Scopri di più! "}
              </a>
          </p>
        </div>
        <header>
          <div className="social">
            <img
              onClick={changeLanguage}
              src={props.isEng ? itFlag : engFlag}
              className="language-flag"
              alt={
                props.isEng
                  ? "bandiera italiana per la selezione della lingua"
                  : "bandiera inglese per la selezione della lingua"
              }
            />
            <a href="https://twitter.com/CoinbarOfficial">
              <img src={twitterLogo} alt="logo di twitter" />
            </a>
            <a href="https://www.facebook.com/romacoinbar/">
              <img src={facebookLogo} alt="logo di facebook" />
            </a>
            <a href="https://www.instagram.com/coinbar.spa/">
              <img src={instagramLogo} alt="logo di instagram" />
            </a>
            <a href="https://t.me/CoinbarITA">
              <img src={telegramIcon} alt="logo di telegram" />
            </a>
          </div>
          <div className="logo">
            <a href="https://www.coinbar.io">
              <img src={logoWhite} alt="logo di coinbar" />
            </a>
          </div>
          <div className="nav">
            <Hamburger />
          </div>
        </header>
      </>
    );
  }
}

export default Header;
