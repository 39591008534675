import React from 'react'
import { Formik, Field } from 'formik';
import './form.scss'
import ita from './ita.json'
import eng from './eng.json'
import axios from 'axios';
// const axios = require('axios');

function FormB2B(props) {
  const [feed, setFeed] = React.useState("")
  let language = props.isEng ? eng : ita
  const sendContact = (valori, callback) => {
    setTimeout(() => {
      axios.post('https://api.coinbar.io/coinbar/api/v2/web/contacts/send', {
        name: valori.nome + " " + valori.cognome,
        email: valori.email,
        title: 'Contatto dal form "Business"',
        message: JSON.stringify(valori, null, 2),
        type: "BUS"
      })
        .then(function (response) {
          setFeed(language.FeedPositivo)
          console.log("SUCCESSO =>", response);
          callback(false)
        })
        .catch(function (error) {
          setFeed(language.FeedNegativo + " " + error.code + " | " + error.message)
          console.log("ERRORE=>", error);
          callback(false)
        });
    }, 400);
  }
  return (

    <Formik
      initialValues={{ nome: "", cognome: "", email: '', iva: "", messaggio: "", privacy: false }}
      validate={values => {
        const errors = {};
        if (!values.email) {
          errors.email = 'Required';
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
          errors.email = 'Invalid email address';
        }
        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => sendContact(values, setSubmitting)}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        /* and other goodies */
      }) => (
        <form onSubmit={handleSubmit} id="form-b2b">
          <input
            required
            className="grid-col-1"
            type="text"
            name="nome"
            placeholder={language.B2B.PlaceholderNome}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.nome}
          />
          <input
            required
            className="grid-col-2"
            type="text"
            name="cognome"
            placeholder={language.B2B.PlaceholderCognome}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.cognome}
          />
          <input
            required
            className="grid-row"
            type="text"
            name="iva"
            placeholder={language.B2B.PlaceholderIva}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.iva}
          />
          <input
            required
            className="grid-row"
            type="email"
            name="email"
            placeholder="Email"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
          />
          <textarea
            required
            className="grid-row"
            name="messaggio"
            placeholder={language.B2B.PlaceholderMessaggio}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.messaggio}
            form="form-b2b"
            rows={8}
          />
          <label>
            <Field required type="checkbox" name="privacy" />
            {language.Accettazione} <a href="/privacy-policy">Privacy Policies</a>
          </label>
          <button type="submit" className="grid-row" disabled={isSubmitting}>
            {language.Bottone}
          </button>
          <p>{feed}</p>
        </form>
      )}
    </Formik>

  );
}

export default FormB2B;
